<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary mb-0">
            <div class="text-primary p-4">
              <h5 class="text-primary">Xác minh bằng Email</h5>
            </div>
          </div>
          <div class="card-body">
            <b-form class="mb-2" @submit.prevent="handleSubmit">
              <div class="mt-2 mb-2">
                <span>Nhập Email của bạn :</span>
              </div>
              <b-form-group>
                <b-form-input type="text" v-model="email" placeholder="Nhập Email"></b-form-input>
              </b-form-group>
              <div v-if="showVerifyEmailMessage" class="alert alert-danger">
                {{ verifyEmailMessage }}
              </div>
              <div class="mt-4">
                <b-button type="submit" variant="primary" class="btn-block">Lấy lại mật khẩu</b-button>
              </div>
              <b-modal v-model="otpModal" id="modal-prevent-closing" title="Đổi mật khẩu" hide-footer>
                <b-form>
                  <b-form-group label="Mã xác thực">
                    <b-form-input id="otp-input" v-model="form.otp"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Nhập mật khẩu mới" id="form-new-password">
                    <b-form-input id="new-pass-input" v-model="form.newPass"
                      :type="isShowNewPass ? 'text' : 'password'"></b-form-input>
                    <img v-if="isShowNewPass" class="hide-icon" src="../../assets/icons/eye.svg" @click="toggleShowNewPassword" />
                    <i v-else class="hide-icon bx bx-hide" @click="toggleShowNewPassword"></i>
                  </b-form-group>
                  <b-form-group label="Xác nhận mật khẩu mới" id="form-confirm-password">
                    <b-form-input id="confirm-pass-input" v-model="form.confirmPass"
                      :type="isShowConfirmPass ? 'text' : 'password'"></b-form-input>
                    <img v-if="isShowConfirmPass" class="hide-icon" src="../../assets/icons/eye.svg" @click="toggleShowConfirmPassword" />
                    <i v-else class="hide-icon bx bx-hide" @click="toggleShowConfirmPassword"></i>
                  </b-form-group>
                  <div v-if="showVerifyMessage" class="alert alert-danger">
                    {{ verifyMessage }}
                  </div>
                  <b-button @click.prevent="changePassword" type="submit" variant="primary" class="btn-block"
                    v-b-modal.modal-prevent-closing>
                    Xác nhận
                  </b-button>
                </b-form>
              </b-modal>
            </b-form>
            <div class="text-center py-4 auth-method">
              <span class="text-primary">Xác thực bằng cách khác</span>
              <div style="font-size: 30px">
                <img style="cursor: pointer" src="../../assets/icons/smartphone.svg" @click="gotoAuthPhone" />
              </div>
              <span class="text-primary" style="font-size: 0.8rem">Xác thực bằng số điện thoại</span>
            </div>
          </div>
          <div class="logo text-center" @click="gotoLogin">
            <span class="text-primary" style="cursor: pointer">
              <img src="../../assets/icons/arrow-left (1).svg" />
              Quay lại</span>
          </div>
        </div>
        <div class="mt-5 text-center">
          <p>© Bản quyền thuộc Hợp tác xã Nông nghiệp số</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from "../../_utils/httpClient";
import Swal from "sweetalert2";
import { router } from "../../router";

export default {
  data() {
    return {
      showVerifyMessage: false,
      verifyMessage: "",
      showVerifyEmailMessage: false,
      verifyEmailMessage: "",
      formCheckEmail: {
        code: "",
        message: "",
        data: "",
      },
      email: "",
      otpModal: false,
      form: {
        otp: "",
        newPass: "",
        confirmPass: "",
      },
      isShowNewPass: false,
      isShowConfirmPass: false,
    };
  },
  watch: {},
  methods: {
    gotoAuthPhone() {
      router.push({ name: "auth.phone" });
    },
    resetForm() {
      (this.form.otp = ""),
        (this.form.newPass = ""),
        (this.form.confirmPass = "");
    },
    async handleSubmit() {
      this.resetForm();
      this.showVerifyEmailMessage = false;
      this.verifyEmailMessage = "";
      if (!this.checkEmail()) {
        return;
      } else {
        httpClient
          .post(`/auth/forgot-password`, {
            id: this.email,
          })
          .then((response) => {
            this.formCheckEmail = response.data;
            if (response.data.code === 0) {
              localStorage.setItem("checkTimeOTP", 1);
              setTimeout(() => {
                localStorage.removeItem("checkTimeOTP");
              }, 300000);
              Swal.fire({
                title: "Vui lòng kiểm tra hộp thư Email của bạn !",
                preConfirm: () => {
                  this.showVerifyEmailMessage = false;
                  this.verifyEmailMessage = "";
                  this.otpModal = true;
                },
              });
            } else {
              this.showVerifyEmailMessage = true;
              this.verifyEmailMessage = response.data.message;
              return;
            }
          });
      }
    },

    checkEmail() {
      let validateEmail =
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.email == "") {
        this.showVerifyEmailMessage = true;
        this.verifyEmailMessage = "Email không được trống !";
        return false;
      }
      if (!validateEmail.test(this.email)) {
        this.showVerifyEmailMessage = true;
        this.verifyEmailMessage = "Email không hợp lệ";
        return false;
      }
      return true;
    },
    verifyOtp() {
      if (
        this.form.otp === this.formCheckEmail.data &&
        localStorage.getItem("checkTimeOTP")
      ) {
        this.showVerifyMessage = false;
        this.verifyMessage = "";
        return true;
      } else if (
        this.form.otp === this.formCheckEmail.data &&
        !localStorage.getItem("checkTimeOTP")
      ) {
        this.showVerifyMessage = true;
        this.verifyMessage = "Hết thời gian xác thực !";
        this.otpModal = false;
        return false;
      } else {
        this.showVerifyMessage = true;
        this.verifyMessage = "Mã xác thực không chính xác !";
        return false;
      }
    },
    toggleShowNewPassword() {
      this.isShowNewPass = !this.isShowNewPass;
    },
    toggleShowConfirmPassword() {
      this.isShowConfirmPass = !this.isShowConfirmPass;
    },
    changePassword() {
      if (!this.validator()) {
        return;
      } else {
        if (this.verifyOtp()) {
          let body = {
            id: this.email,
            otp: this.formCheckEmail.data,
            password: this.form.newPass,
          };

          httpClient.post(`/auth/reset-password`, body).then((response) => {
            if (response.data.code === 0) {
              Swal.fire({
                title: "Đổi mật khẩu thành công",
                icon: "success",
                preConfirm: () => {
                  router.push("/", () => { });
                },
              });
            }
          });
        }
      }
    },
    hasWhiteSpace(s) {
      return s.indexOf(" ") >= 0;
    },
    validator() {
      let formPass = {
        ...this.form,
      };
      let mappingErrorName = {
        otp: "Mã xác thực",
        newPass: "Mật khẩu mới",
        confirmPass: "Xác nhận mật khẩu mới",
      };

      for (let key in formPass) {
        if (formPass[key] !== null && formPass[key].length < 1) {
          this.showVerifyMessage = true;
          this.verifyMessage = mappingErrorName[key] + " không được trống";
          return false;
        }
      }
      if (formPass.otp.length !== 6) {
        this.showVerifyMessage = true;
        this.verifyMessage = "Mã xác thực phải có 6 số";
        return false;
      }
      if (formPass.newPass.length < 6) {
        this.showVerifyMessage = true;
        this.verifyMessage = "Mật khẩu có ít nhất 6 kí tự";
        return false;
      }
      if (this.hasWhiteSpace(formPass.newPass)) {
        this.showVerifyMessage = true;
        this.verifyMessage = "Mật khẩu không được có khoảng trắng !";
        return false;
      }
      if (formPass.newPass !== formPass.confirmPass) {
        this.showVerifyMessage = true;
        this.verifyMessage = "Mật khẩu không trùng khớp";
        return false;
      }

      return true;
    },
    gotoLogin() {
      router.push({ name: "auth.login" });
    },
  },
  created() {
  },
};
</script>
<style scoped>
.auth-method {
  position: relative;
}

.auth-method::before {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background-color: #556ee6;
  top: 14px;
  left: 18px;
}

.auth-method::after {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background-color: #556ee6;
  bottom: 15px;
  left: 18px;
}

.hide-icon {
  position: absolute;
  right: 12px;
  top: 34%;
  cursor: pointer;
  font-size: 20px;
}

#form-confirm-password,
#form-new-password {
  position: relative;
}
</style>
